<template>
  <div>

    <section id="game" :class="$parent.questionStateClass"
      v-if="!$parent.ShowVideoLoop && $parent.currQuestionIndex != -1 && $parent.serverState < 3 && $parent.currQuestion != undefined">

      <div class="main has-text-black-bis">

            <h2 v-if="$parent.questionState == 0" class="title is-2">{{ $t('exp_trivia.getReadyToPlay') }}</h2>

            <div v-if="$parent.questionState != 0" class="pregindicator">

              <div class="texto">
                {{ $parent.currQuestionIndex + 1 }}/{{ $parent.selectedQuestions.length }}
              </div>

              <progress-circle class="active"
                :diameter="83"
                :circle-width="7"
                :completed-steps="$parent.clockNormalized * 100"
                :total-steps="100"
                circle-color="#DBDBDB"
                start-color="#FF77C3"
                stop-color="#FF77C3"
                inner-color="#fff"
                :animation-duration="10">
                <span class="has-text-weight-bold">
                  {{ $parent.clockDisplay }}
                </span>
              </progress-circle>

            </div>

            <div v-for="question in $parent.questions" :key="question.video.id">
              <video v-if="$parent.currQuestion.video.id == question.video.id" width="216" class="pregvideo" :ref="`videoQuestion-${question.video.id}`" :src="encodeURI(question.video.url)" autoplay looped muted/>
            </div>

      </div>

      <!-- soreboard -->
      <div style="display:none">
        <div class="score pt-3">
          <div class="header">{{ $t('exp_trivia.score') }}</div>
          <TransitionGroup name="list" class="scoreboard" tag="ul">
            <div v-for="player in $parent.playersScoreboard.slice(0, maxVisibleUsers)" class="item" :key="player.dbid">
              <player-avatar :player="player" :scale="2" :showUsername="false" />
              <span class="username">{{ player.username }}</span>
              <span>
                <animated-number :number="Math.round(player.score)" :duration=".7" />
              </span>
            </div>
          </TransitionGroup>
          <div v-if="$parent.playersScoreboard.length > maxVisibleUsers" class="item-extra-users">
            + {{ $tc('exp_trivia.otherUsers', $parent.playersScoreboard.length - maxVisibleUsers) }}
          </div>
        </div>
      </div>


    </section>

    <Transition name="slidebottom">
      <section class="has-text-black-bis" v-if="$parent.serverState == 3" id="finalScore">
        <!-- Final Screen -->
        
        <template v-if="$parent.playersScoreboard[0].score > 0">
          <!-- Podio -->
          <div class="final scoreboard">
            <div class="header">{{ $parent.playersScoreboard.length == 1 ? $t('exp_trivia.winner') : $t('exp_trivia.firstPlace') }}</div>
            <div class="podium">

              <div class="first">
                <div v-if="$parent.playersScoreboard.length > 0" class="user has-text-weight-bold">
                  <player-avatar :player="$parent.playersScoreboard[0]" :scale="5" :showUsername="false" />
                  <div class="username">{{ $parent.playersScoreboard[0].username }}</div>
                  <div class="score">{{ Math.round($parent.playersScoreboard[0].score) }}</div>
                </div>
              </div>

            </div>
             
            <div class="others mt-3">
              <div v-for="(player, index) in $parent.playersScoreboard.slice(1, 8)" :key="player.dbid" class="item" :class="['pos-' + (index+2)]">
                <div class="rank">{{ $t('exp_trivia.ordinal_n', [index + 2]) }}</div>
                <player-avatar :player="player" :scale="3" :showUsername="false" />
                <div class="username">{{ player.username }}</div>
                <div class="score">{{ Math.round(player.score) }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Empate -->
          <div class="final scoreboard tie">
            <svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1162_22366)">
                    <path d="M167 89.5C167 129.54 134.54 162 94.5 162C54.4639 162 22 129.54 22 89.5C22 49.4639 54.4639 17 94.5 17C134.54 17 167 49.4639 167 89.5Z" fill="#FFCC4D"/>
                    <path d="M91.7287 87.9409C91.0198 87.3649 90.0088 87.3488 89.2717 87.8845C89.2234 87.9208 84.1967 91.5176 74.3609 91.5176C64.5291 91.5176 59.4984 87.9208 59.4581 87.8926C58.721 87.3408 57.706 87.3569 56.9971 87.9288C56.2802 88.5008 56.0385 89.4836 56.4091 90.3213C56.6508 90.8651 62.4508 103.601 74.3609 103.601C86.2751 103.601 92.071 90.8651 92.3127 90.3213C92.6833 89.4876 92.4416 88.5129 91.7287 87.9409ZM132.006 87.9409C131.298 87.3649 130.291 87.3448 129.549 87.8845C129.501 87.9208 124.47 91.5176 114.639 91.5176C104.811 91.5176 99.7762 87.9208 99.7359 87.8926C98.9988 87.3408 97.9878 87.3569 97.2749 87.9288C96.558 88.5008 96.3163 89.4836 96.6869 90.3213C96.9285 90.8651 102.729 103.601 114.639 103.601C126.553 103.601 132.349 90.8651 132.59 90.3213C132.961 89.4876 132.719 88.5129 132.006 87.9409ZM110.611 129.781H78.3887C76.1653 129.781 74.3609 127.981 74.3609 125.754C74.3609 123.526 76.1653 121.726 78.3887 121.726H110.611C112.838 121.726 114.639 123.526 114.639 125.754C114.639 127.981 112.838 129.781 110.611 129.781ZM46.1664 73.3926C43.9431 73.3926 42.1387 71.5881 42.1387 69.3648C42.1387 67.1455 43.931 65.3451 46.1503 65.337C46.7787 65.329 60.5255 64.9906 71.1427 50.837C72.4759 49.0648 74.9973 48.6942 76.7816 50.0315C78.5619 51.3647 78.9203 53.8901 77.5871 55.6704C64.4445 73.1912 46.9076 73.3926 46.1664 73.3926ZM142.833 73.3926C142.092 73.3926 124.559 73.1912 111.416 55.6704C110.079 53.8901 110.442 51.3647 112.222 50.0315C113.998 48.6902 116.52 49.0567 117.857 50.837C128.494 65.0188 142.269 65.333 142.853 65.337C145.069 65.3612 146.857 67.1737 146.845 69.3849C146.837 71.6042 145.048 73.3926 142.833 73.3926Z" fill="#664500"/>
                </g>
                <defs>
                    <filter id="filter0_d_1162_22366" x="0" y="0" width="189" height="189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="5"/>
                        <feGaussianBlur stdDeviation="11"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1162_22366"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1162_22366" result="shape"/>
                    </filter>
                </defs>
            </svg>

            <div class="big-title">{{ $t('exp_trivia.deadHeat') }}</div>
          </div>
        </template>
        <canvas id="confetti-canvas"></canvas>
      </section>
    </Transition>

  </div>
</template>

<script>
import gsap from "gsap";
import AnimatedNumber from "../AnimatedNumber.vue";
import { ProgressCircle } from 'vue-progress-circle';
import PlayerAvatar from '../PlayerAvatar.vue';
import "./screenTriviaDefaultGame.scss";

export default {
  components: {
    AnimatedNumber,
    ProgressCircle,
    PlayerAvatar
  },
  data() {
    return {
      maxVisibleUsers: 10,
    };
  },
  computed: {
    preguntaActual() {
      return this.$parent.currQuestion
    }
  },
  watch: {
    preguntaActual(newValue,oldValue){

      console.log('CAMBIO VIEJO',oldValue?.id)
      console.log('CAMBIO NUEVO',newValue?.id)

      // if(oldValue?.id) this.$refs[`videoQuestion-${oldValue.id}`].stop()
      // if(newValue?.id) this.$refs[`videoQuestion-${newValue.id}`].play()

    }
  },
  methods: {
    // Animaciones
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.7
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.2,
        onComplete: done,
      });
    },
    optionGuessed(answer) {
      return this.$parent.currQuestion.options.find(x => x.id == answer.id).guessed
    }
  }
};
</script>